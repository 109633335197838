<template>
	<main class="main">
	 <div class="content">
		<div class="container content__container content__container--w-1074">
			<div class="account-verification satefy">
			 <div class="account-verification__head">
				<h1 class="account-verification__title">{{$t('partner_title')}}</h1>
				<div class="parlink">
					<a :href="$t('partner_link')" class="btn btn-info" target="_blank">{{$t('pdf_title')}}</a>
				</div>
			 </div>
			 <div class="account-verification__body">

				

				<div class="ref-body">

					<div class="reflink-box boxed">
						<div class="reflink-box-title boxed-title flex">
							<span>{{$t('your_ref_link')}}</span>
						</div>
						<div class="reflink-box-content boxed-content">
							<div class="reflink-labels">
								<div class="reflink-label width-100">
									<input type="text" class="input-class ref-input-class" v-model="reflink" id="reflink" onfocus="this.select();" @click="copyRefLink()" readonly>
								</div>
							</div>
						</div>
					</div>

					<div class="reflink-box boxed" style="margin: 33px 0">
						<div class="reflink-box-title boxed-title flex">
							<span>{{$t('profit_partner_program')}}</span>
						</div>
						<div class="reflink-box-content boxed-content">

							<div class="ref-two flex">

								<div class="ref-two-col ref-two-col-1">
									<div class="reflink-box boxed">
										<div class="reflink-box-title boxed-title flex">
											<span class="flex"><img class="cryptocurrency__img" src="/img/usdt.8b34d54c.svg">USDT</span>
										</div>
										<div class="reflink-box-content boxed-content">
											<ul class="rinfo">
												<li>{{$t('all')}}: <b>{{ partnerAllUsdtSumm }} USDT</b></li>
											</ul>
										</div>
									</div>
								</div>

								<div class="ref-two-col ref-two-col-2">
									<div class="reflink-box boxed">
										<div class="reflink-box-title boxed-title flex">
											<span class="flex"><img class="cryptocurrency__img" src="/img/rend.2229445f.svg"> REND</span>
										</div>
										<div class="reflink-box-content boxed-content">
											<ul class="rinfo">
												<li>{{$t('all')}}: <b>{{ partnerAllRendSumm }} REND</b></li>
												<li style="font-size: 11px">{{$t('from_commission')}}: <b>{{ partnerAllRendSummComs }} REND</b> | {{$t('from_stacking')}}: <b>{{ partnerAllRendSummStack }} REND</b></li>
											</ul>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>

					<div class="reflink-box boxed" style="margin: 0 0 33px 0" v-if="inviterEmail != ''">
						<div class="reflink-box-title boxed-title flex">
							<span>{{$t('your_inviter')}}</span>
						</div>
						<div class="reflink-box-content boxed-content">
							<div class="reflink-label width-100">
									<input type="text" class="input-class ref-input-class" id="inviter" v-model="inviterEmail" readonly>
								</div>
						</div>
					</div>

					<div class="reflink-box boxed" style="margin: 0 0 33px 0">
						<div class="reflink-box-title boxed-title flex">
							<span>{{$t('your_structure')}} ({{$t('all_partners')}}— {{ countPartners }})</span>
						</div>
						<div class="reflink-box-content boxed-content">
							<div id="tree"></div>
						</div>
					</div>


					<div class="reflink-box boxed" style="margin: 0 0 33px 0">
						<div class="reflink-box-title boxed-title flex">
							<span>{{$t('your_career')}}</span>
						</div>
						<div class="reflink-box-content boxed-content">

							<div class="rtabs">

								<div class="rtabs-items is-flex">
									<div class="rtabs-item" @click="showTab('commission')" :class="activeTab == 'commission' ? 'active' : ''">{{$t('partner_commission')}}</div>
									<div class="rtabs-item" @click="showTab('stacking')" :class="activeTab == 'stacking' ? 'active' : ''">{{$t('partner_stackings')}}</div>
								</div>

								<div class="rtabs-divs">

									<div class="rtab-div" v-if="activeTab == 'commission'">
										<div class="ref-table ref-table-traders">
											<table>
												<thead>
													<tr>
														<th>{{$t('partner_step')}}</th>
														<th>{{$t('partner_inviters')}}</th>
														<th>{{$t('partner_percent')}}</th>
														<th>{{$t('partner_my_rend')}}</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, i) in steps" :key="i" :class="myStepTrade == item.level ? 'current' : ''" :title="myStepTrade == item.level ? 'Ваш текущий шаг в карьере' : ''">
														<td :data-level="item.level">{{ item.level }}</td>
														<td v-if="myStepTrade == item.level">{{ partnerInfo.traders.count_ref ? partnerInfo.traders.count_ref : 0 }} / {{ item.refs }}</td>
														<td v-if="myStepTrade != item.level">{{ item.refs }}</td>
														<td>{{ item.percent }}</td>
														<td v-if="myStepTrade == item.level">{{ partnerInfo.traders.user_rend ? partnerInfo.traders.user_rend : 0 }} / {{ item.rend }}</td>
														<td v-if="myStepTrade != item.level">{{ item.rend }}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class="rtab-text" v-html="this.$t('partner_text_1')">
										</div>
									</div>

									<div class="rtab-div"  v-if="activeTab == 'stacking'">
										<div class="ref-table ref-table-holders">
											<table>
												<thead>
													<tr>
														<th>{{$t('partner_step')}}</th>
														<th>{{$t('partner_inviters')}}</th>
														<th>{{$t('partner_percent')}}</th>
														<th>{{$t('partner_my_rend')}}</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, i) in steps" :key="i" :class="myStepHold == item.level ? 'current' : ''" :title="myStepHold == item.level ? 'Ваш текущий шаг в карьере' : ''">
														<td :data-level="item.level">{{ item.level }}</td>
														<td v-if="myStepHold == item.level">{{ partnerInfo.holders.count_ref ? partnerInfo.holders.count_ref : 0 }} / {{ item.refs }}</td>
														<td v-if="myStepHold != item.level">{{ item.refs }}</td>
														<td>{{ item.percent }}</td>
														<td v-if="myStepHold == item.level">{{ partnerInfo.holders.user_rend ? partnerInfo.holders.user_rend : 0 }} / {{ item.rend }}</td>
														<td v-if="myStepHold != item.level">{{ item.rend }}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class="rtab-text" v-html="this.$t('partner_text_2')">
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>


					<div class="reflink-box boxed">
						<div class="reflink-box-title boxed-title flex">
							<span>{{$t('history_title')}}</span>
						</div>
						<div class="reflink-box-content boxed-content">
							<div class="smart-table smart-table--partner-history">
								<table class="table smart-table__table">
									<thead class="smart-table__thead">
										<tr class="smart-table__row">
											<th class="smart-table__cell" data-column-id="date">
												<div class="smart-table__sort-by">
													{{$t('date')}}
												</div>
											</th>
											<th class="smart-table__cell" data-column-id="cryptocurrency">
												<div class="smart-table__sort-by">
													{{$t('coin')}}
												</div>
											</th>
											<th class="smart-table__cell" data-column-id="sum">
												<div class="smart-table__sort-by">
													{{$t('sum')}}
												</div>
											</th>
											<th class="smart-table__cell" data-column-id="transaction">
												<div class="smart-table__sort-by">
													{{$t('transaction')}}
												</div>
											</th>
										</tr>
									</thead>
									<tbody class="smart-table__tbody">
										<tr class="smart-table__row" v-for="item in history">
											<td class="smart-table__cell" data-column-id="date"><span>{{item.stamp}}</span></td>
											<td class="smart-table__cell" data-column-id="cryptocurrency">
												<div class="cryptocurrency" v-if="item.coin == 1">
													<img class="cryptocurrency__img" src="/img/rend.2229445f.svg">
													<div class="cryptocurrency__name ms-2">
														REND
													</div>
												</div>
												<div class="cryptocurrency" v-if="item.coin == 2">
													<img class="cryptocurrency__img" src="/img/usdt.8b34d54c.svg">
													<div class="cryptocurrency__name ms-2">
														USDT
													</div>
												</div>
											</td>
											<td class="smart-table__cell" data-column-id="sum"><span class="plus">{{item.amount}}</span></td>
											<td class="smart-table__cell" data-column-id="transaction" v-html="item.comment">
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>

			 </div>
			</div>
		</div>
	 </div>
	</main>
</template>

<script>
import { api } from "@/api.ts";
import TopNav from '@/components/TopNav/TopNav';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu';
import '@/assets/css/style_main.min.css';
import {commitToggleModal} from '@/store/main/mutations';
import {readUser} from '@/store/main/getters';
import axios from 'axios';
import {readUserFp} from '@/store/main/getters';
import {commitSetUser} from '@/store/main/mutations';
import VueQRCodeComponent from 'vue-qr-generator';

export default {
	components: {
	 TopNav,
	 ProfileMenu,
	 VueQRCodeComponent
	},
	name: 'Partner',
	data() { return {
		tree: '',
		reflink: '',
		profile: [],
		allCount: 0,
		allFirstcount: 0,
		showStat: 0,
		chartData: [],
		history: [],
		steps: [],
		myStepTrade: 0,
		myStepHold: 0,
		activeTab: 'commission',
		partnerAllUsdtSumm: 0,
		partnerAllRendSumm: 0,
		partnerAllUsdtSummComs: 0,
		partnerAllRendSummComs: 0,
		partnerAllRendSummStack: 0,
		partnerInfo: [],
		countPartners: 0,
		inviterEmail: ''
 }},
 computed: {
	user() {
		return readUser(this.$store);
	},
 },
 methods: {

 	showTab(tab) {
 		this.activeTab = tab;
 	},

	loadProfile(){
			axios.post(this.$store._modules.root.state.main.API_URL+'/user',
					{method: 'loadProfile',
						data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
				console.log('loadProfile', r.data);
				if (typeof(r.data.data.profile)=='object') {
					this.profile = r.data.data.profile;
					if(r.data.data.profile.invite_code) {
						this.reflink = window.location.protocol+'//'+window.location.host+'/?invite_code='+r.data.data.profile.invite_code
					}
				}
			});
		},

	getTree() {
		 axios.post(this.$store._modules.root.state.main.API_URL+'/partner',
				 {method: 'getTree',
					data: { fp: readUserFp(this.$store), uid: this.user.uid, json: true}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {

			console.log(r.data);
			if(r.data.length) {
				this.countPartners = r.data.length-1;
			}

			OrgChart.templates.diva.ripple = {
        		radius: 6
    		};

			var list = r.data;

			var chart = new OrgChart(document.getElementById('tree'), {
				lazyLoading: false,
				nodeBinding: {
					field_0: "Email",
					field_2: this.$t('rend_sum'),
					field_3: this.$t('usdt_sum'),
					field_4: this.$t('career_steps'),
					img_0: "img"
				},
				tags: {
					"New": {
							template: "color1"
					},
					"Old": {
							template: "color2"
					},
					"Main": {
							template: "color3"
					}
				},
				nodes: list,
				mode: "dark",
				template: "diva",
				//clinks: clinks
				/*,
			nodes: l*/
			});
			chart.on('label', function (sender, args) {
					args.value = "Since " + args.value;
			});
			//chart.load(list);
			
		})
	},


	getPartnerHistory() {
		 axios.post(this.$store._modules.root.state.main.API_URL+'/partner',
				 {method: 'getHistory',
					data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {

			if(r.data.code == 1) {
				this.history = JSON.parse(r.data.data.json);
			}

			//console.log(this.history);
			
		})
	},

	getMyPartnerInfo() {
		 axios.post(this.$store._modules.root.state.main.API_URL+'/partner',
				 {method: 'getMyInfo',
					data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {

			var result = r.data.data.result;

			if(result.traders) {
				this.myStepTrade = result.traders.step;
			}
			if(result.holders) {
				this.myStepHold = result.holders.step;
			}
			if(result.partnerAllRendSumm) {
				this.partnerAllRendSumm = result.partnerAllRendSumm;
			}
			if(result.partnerAllUsdtSumm) {
				this.partnerAllUsdtSumm = result.partnerAllUsdtSumm;
			}
			if(result.partnerAllUsdtSummComs) {
				this.partnerAllUsdtSummComs = result.partnerAllUsdtSummComs;
			}
			if(result.partnerAllUsdtSumm) {
				this.partnerAllRendSummComs = result.partnerAllRendSummComs;
			}
			if(result.partnerAllRendSummStack) {
				this.partnerAllRendSummStack = result.partnerAllRendSummStack;
			}
			this.partnerInfo = result;

			if(result.inviterEmail) {
				this.inviterEmail = result.inviterEmail;
			}

			console.log('myInfo', this.partnerInfo);
			
			
		})
	},


	getSteps() {

		this.steps =  [
			{level: 1, refs: 10, percent: 1, rend: 1500},
			{level: 2, refs: 20, percent: 2, rend: 2500},
			{level: 3, refs: 30, percent: 4, rend: 3500},
			{level: 4, refs: 40, percent: 6, rend: 4500},
			{level: 5, refs: 50, percent: 8, rend: 5500},
			{level: 6, refs: 60, percent: 10, rend: 7500},
			{level: 7, refs: 70, percent: 12, rend: 9500},
			{level: 8, refs: 80, percent: 14, rend: 12500},
			{level: 9, refs: 90, percent: 15, rend: 25500},
			{level: 10, refs: 100, percent: 16, rend: 50500},
		];

	},

	copyRefLink() {
      if(this.reflink == '') return;
      const testingCodeToCopy = document.querySelector('#reflink');
      // @ts-ignore
      testingCodeToCopy.setAttribute('type', 'text');
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        const range = document.createRange();
        // @ts-ignore
        range.selectNode(testingCodeToCopy);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      } else {
        // @ts-ignore
        testingCodeToCopy.select();
      }

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$noty.success(this.$t('Copied successfully'), { timeout: 2000, progressBar: true });
      } catch (err) {
        this.$noty.error(this.$t('Error, unable to copy'), { timeout: 2000, progressBar: true });
      }
      // @ts-ignore
      //testingCodeToCopy.setAttribute('type', 'hidden');
      // @ts-ignore
      window.getSelection().removeAllRanges();
    },

 },
 mounted() {

	this.getTree();
	this.getPartnerHistory();
	this.loadProfile();
	this.getSteps();
	this.getMyPartnerInfo();
 }
}

</script>
